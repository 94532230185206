import { RESEND_VERIFICATION_CODE_MILLIS } from "@/constants/app";
import { defer } from "@/utils";

/**
 * @description Handles the resend MFA code to user phone number functionality
 * @author {Jatin Kamboj}
 */
export default {
  name: "ResendFirebaseTextCode",
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      resendCodeTimer: null,
      isCodeSending: false,
      isResendCodeAllowed: false,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * resetResendCodeVerificationTimer
     * @description Resets isResendCodeAllowed true after 15000 milli seconds
     */
    resetResendCodeVerificationTimer() {
      this.isResendCodeAllowed = false;

      this.resendCodeTimer = defer(() => {
        this.isResendCodeAllowed = true;
      }, RESEND_VERIFICATION_CODE_MILLIS);
    },
    /**
     * resendVerificationCode
     * @description Resend code (OTP) to user MFA phone number
     * @warning Define errorHandler function in parent component to handle the errors
     */
    async resendVerificationCode() {
      try {
        this.isCodeSending = true;
        const widgetId = await this.recaptchaVerifier.render();

        await window.grecaptcha.reset(widgetId);
        await this.sendVerificationMessage();
        this.resetResendCodeVerificationTimer();
      } catch (error) {
        this.errorHandler(error);
        return Promise.reject(error);
      } finally {
        this.isCodeSending = false;
      }
    },
    /**
     * clearResendCodeTimer
     * Clears resend code timer
     */
    clearResendCodeTimer() {
      this.isResendCodeAllowed = false;
      if (this.resendCodeTimer) {
        clearTimeout(this.resendCodeTimer);
        this.resendCodeTimer = null;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Destroyed lifecycle hook
  |--------------------------------------------------
  */
  destroyed() {
    this.clearResendCodeTimer();
  },
};
