<template>
  <div id="authenticator-app-mfa-setup">
    <v-row>
      <v-col>
        <h3
          :class="headerClass"
          class="secondary--font text--primary font-weight-large pb-4"
        >
          {{ title }}
        </h3>
        <div class="text--secondary body-2 pb-4 text-left">
          {{ subtitle }}
        </div>
        <div
          id="authenticator-app-setup__instructions"
          class="text--secondary body-2 text-left"
        >
          <ol class="pl-4">
            <li class="pb-4 authenticator-app-setup__instruction">
              Install any security app on your phone. Maropost recommends the
              Microsoft Authenticator
              <a
                target="blank"
                v-text="'iOS'"
                class="light-blue--text"
                href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
              />
              or
              <a
                target="blank"
                v-text="'Android'"
                class="light-blue--text"
                href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
              />
              app.
            </li>
            <template v-for="(instruction, index) in instructions">
              <div :key="instruction">
                <li class="pb-4 authenticator-app-setup__instruction">
                  {{ instruction }}
                </li>
              </div>
              <!-- Slots to render custom element under a instruction
                Name of the slot will be (instruction_{{index}}) here index stands for index of current instruction -->
              <slot
                :name="`instruction_${index + 1}`"
                :instruction="instruction"
              />
            </template>
          </ol>
        </div>
        <slot name="footer" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { hasProp } from "@/utils";

/**
 * Authenticator app mfa setup form
 * @description To be used to setup authenticator app mfa for a user
 * @author {Jatin Kamboj}
 */
export default {
  name: "AuthenticatorAppMfaSetupForm",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    headerClass: { type: String, default: "body-1" },
    title: { type: String, default: "", required: true },
    subtitle: { type: String, default: "", required: true },
    instructions: {
      type: Array,
      default: () => [
        "Open your app and add new account.",
        "Scan the QR code below using the Security app or enter the  code manually.",
        "Enter the 6-digit verification code from the app below.",
      ],
    },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {};
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    hasProp,
  },
};
</script>
