<template>
  <div class="enter-mfa-otp">
    <v-form
      ref="form"
      v-model="isFormValid"
      @input="broadcastFormStatus"
      @submit.prevent="submitHandler"
    >
      <v-row v-if="showHeader">
        <v-col cols="12" lg="12" md="12">
          <slot v-if="hasProp($slots, 'heading')" name="heading" />
          <h2
            v-else
            v-text="heading"
            class="body-1 text--primary font-weight-large secondary--font"
          />
          <slot name="subHeading">
            <p class="body-2 text--secondary pt-3 text-left">
              Enter the 6-digit verification code sent to your phone number ({{
                phoneNumber
              }}) via text message to {{ prependText }}
            </p>
          </slot>
        </v-col>
      </v-row>
      <slot name="alertMessage" />
      <template v-if="showResendCodeOption">
        <alert-message
          :message="successMessage"
          :alert-type="this.$appConfig.alert.types.success"
        />
        <v-row no-gutters>
          <v-col cols="12" lg="12" class="d-flex justify-center">
            <tooltip
              width="20%"
              type="dark"
              :name="codeErrorMessage"
              :disabled="isTooltipDisabled"
            >
              <template #default="{ on, attrs }">
                <div
                  v-on="on"
                  v-bind="attrs"
                  :class="{
                    'cursor-not-allowed': !isTooltipDisabled,
                  }"
                >
                  <v-btn
                    text
                    color="dark-black"
                    :loading="isCodeSending"
                    @click="initiateResendVerificationCode"
                    :disabled="!isResendCodeButtonEnabled"
                    :class="resendCodeButtonClasses"
                    class="font-weight-large white--text"
                  >
                    <v-icon left class="material-icons-outlined">
                      refresh
                    </v-icon>
                    Resend Code
                  </v-btn>
                </div>
              </template>
            </tooltip>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col class="d-flex justify-lg-space-between">
          <template v-for="(num, index) in 6">
            <v-text-field
              counter
              outlined
              :key="num"
              inputmode="numeric"
              hide-details
              maxlength="1"
              :rules="[required]"
              :ref="`otp${index}`"
              :id="`enter-otp-input-field-${index}`"
              v-model="otp[index]"
              :class="num < 6 ? textFieldClass : ''"
              @paste="onPaste($event, index)"
              @input="focusInputField($event, index)"
              class="text-center otp-input-field"
              @keydown="deleteOtpNumber($event, index, otp[index])"
              @keypress="validateNumericKeys"
            />
          </template>
        </v-col>
      </v-row>
      <!-- Displays error message to user in alert element -->
      <div class="pt-5" v-if="errorMessage">
        <alert-message :message="errorMessage" />
      </div>
      <slot name="buttons" v-if="hasProp($slots, 'buttons')" />
      <v-row v-else>
        <v-col lg="12" md="12" cols="12" class="d-flex justify-space-between">
          <v-btn
            x-large
            outlined
            @click="reAuthenticate"
            v-track="'login-page-back-btn'"
            class="px-13 font-weight-bold secondary--font"
          >
            Back
          </v-btn>

          <v-btn
            x-large
            type="sumbit"
            color="dark-black"
            :loading="isLoading"
            :disabled="!isFormValid"
            v-track="trackId"
            :class="{ 'white--text': isLoading }"
            class="px-13 font-weight-bold secondary--font white--text"
          >
            Login
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showSupport" no-gutters>
        <v-col cols-="12" class="pt-6">
          <div class="caption text--secondary">Having trouble logging in?</div>
          <div class="pt-1 body-2 cursor-pointer font-weight-large">
            <a
              v-track="'contact-maropost-support-button'"
              href="mailto:support@maropost.com?subject=Support"
              class="text--secondary text-decoration-none"
            >
              Contact Support</a
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { defer, hasProp, isType } from "@/utils";
import { numeric, onlyNumberKey } from "@/validators/form-validators";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import { AUTH_MESSAGES } from "@/constants/app";
import Tooltip from "@/components/shared/Tooltip.vue";

/**
 * Enter otp recieved on user phone number
 * @author {Jatin Kamboj}
 */
export default {
  name: "EnterOTPForm",
  /**
  |--------------------------------------------------
  | Custom events emitted
  |--------------------------------------------------
  */
  emits: ["submit", "reauthenticate", "form-status"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Phonenumber to which OTP was sent
     */
    phoneNumber: { type: [String], required: true, default: "" },
    /**
     * Error message to be shown in alert tag
     */
    errorMessage: { type: [String], required: false, default: "" },
    isLoading: { type: Boolean, default: false },
    wrapperWidth: { type: String, default: "392px" },
    heading: { type: String, default: "" },
    prependText: { type: String, default: "" },
    textFieldClass: { type: String, default: "pr-1" },
    /**
     * Gainsight id for tracking submit button dom element
     */
    trackId: { type: String, default: "" },
    showHeader: { type: Boolean, default: true },
    showSupport: { type: Boolean, default: false },
    resendVerificationCode: { type: Function, default: () => {} },
    isCodeSending: { type: Boolean, default: false },
    showResendCodeOption: { type: Boolean, default: false },
    isResendCodeAllowed: { type: Boolean, default: false },
    resendCodeButtonClasses: { type: String, default: "" },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      otp: new Array(6).fill(""),
      isFormValid: false,
      resendCodeAttempts: 0,
      maxResendCodeAttempts: 3,
      afterExhaustedAttemptsTime: 5 * 60 * 1000,
      successMessage: "",
      codeTimerRef: null,
      codeErrorMessage: AUTH_MESSAGES.codeReattemptsExhausted,
    };
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AlertMessage, Tooltip },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    /**
     * Watches otp entered by user and auto submits the form
     */
    otp() {
      const otpVal = this.getOtp();
      if (otpVal?.length == 6) this.$emit("submit", otpVal);
    },
    /**
     * isMaximumAttemptsExhausted
     * @description Determines whether maximum code attempts are exhausted or not
     */
    isMaximumAttemptsExhausted(val) {
      if (val) {
        this.codeErrorMessage = AUTH_MESSAGES.codeReattemptsExhausted;

        this.codeTimerRef = defer(
          () => (this.resendCodeAttempts = 0),
          this.afterExhaustedAttemptsTime
        );
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    isTooltipDisabled() {
      return !this.isMaximumAttemptsExhausted;
    },
    isMaximumAttemptsExhausted() {
      return this.resendCodeAttempts >= this.maxResendCodeAttempts;
    },
    isResendCodeButtonEnabled() {
      return this.isResendCodeAllowed && !this.isMaximumAttemptsExhausted;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Resets the form
     */
    resetForm() {
      this.otp = new Array(6).fill("");
      this.resetValidation();
      this.updateOtpField(0, "");
    },
    /**
     * Resets form validations
     */
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    /**
     * Copies text pasted by the user
     */
    clipboardText(event) {
      return (event.clipboardData || window.clipboardData).getData("text");
    },
    /**
     * onPaste
     * @description Listesn on paste event on otp input fields
     */
    onPaste(event, inputIndex) {
      let pastedOtp = this.clipboardText(event);
      const isOtpValid = isType(numeric("")(pastedOtp), "boolean");

      // Blocks input of a string value
      if (!isOtpValid) return event.preventDefault();

      pastedOtp = pastedOtp.split("");
      if (inputIndex > 0) {
        pastedOtp = pastedOtp.slice(0, this.otp?.length - inputIndex);
      }

      pastedOtp.forEach((otpNum, index) => {
        if (inputIndex === 0) {
          this.updateOtpField(index, otpNum);
        } else {
          this.updateOtpField(inputIndex, otpNum);
          inputIndex++;
        }
      });
    },
    /**
     * updateOtpField
     * @description Updates value of otp input field
     */
    updateOtpField(inputIndex, value) {
      this.$nextTick(() => {
        this.otp[inputIndex] = value;
        defer(() => this.$refs[`otp${inputIndex}`]?.[0]?.focus(), 10);
      });
    },
    onlyNumberKey,
    hasProp,
    required: (val) => !!val || "",
    /**
     * validateNumericKeys
     * @description Only allows numeric value keys to be registered
     */
    validateNumericKeys(event) {
      const SAFARI_PASTE_KEY_CODE = 118;
      const pressedKey = event.which ? event.which : event.keyCode;

      return pressedKey === SAFARI_PASTE_KEY_CODE || onlyNumberKey(event);
    },
    /**
     * initiateResendVerificationCode
     * @description Intiate resend verification code flow
     */
    async initiateResendVerificationCode() {
      this.successMessage = "";
      this.codeErrorMessage = "";

      if (this.isMaximumAttemptsExhausted) {
        this.codeErrorMessage = AUTH_MESSAGES.codeReattemptsExhausted;
      } else {
        await this.resendVerificationCode();
        this.successMessage = AUTH_MESSAGES.codeResentSuccessfully;
        this.resendCodeAttempts += 1;
        this.focusInputFieldOnMount();
      }
    },
    /**
     * Form submit handler
     * @emits submit Event in the parent component
     */
    submitHandler() {
      if (!this.isFormValid) return this.$refs.form.validate();
      this.$emit("submit", this.getOtp());
    },
    /**
     * Compute the OTP entered by the user
     * @returns {String} OTP
     */
    getOtp() {
      return Array.isArray(this.otp) ? this.otp.join("") : "";
    },
    /**
     * Simply auto focuses on next input field for user to enter next otp number
     */
    focusInputField(val, refIndex) {
      const ref = this.$refs[`otp${refIndex + 1}`];
      if (val && ref) ref[0].focus();
    },
    /**
     * Refocuses on previous otp input field if Backspace is pressed
     */
    deleteOtpNumber(event, refIndex, value) {
      if (
        (event.code === "Backspace" || Number(event?.keyCode) === 8) &&
        !value
      ) {
        const ref = this.$refs[`otp${refIndex - 1}`];
        if (event && ref) ref[0].focus();
      }
    },
    /**
     * Focus first input field on component mount
     */
    focusInputFieldOnMount() {
      this.$nextTick(() => this.$refs?.otp0?.[0]?.focus());
    },
    /**
     * Broadcasts current form validation status
     * @emits form-status
     * @param {Boolean} val
     */
    broadcastFormStatus(val) {
      this.$emit("form-status", val);
    },
    /**
     * Updates the parent component to re authenticate the user
     * @emits reauthenticate
     */
    reAuthenticate() {
      this.$emit("reauthenticate");
    },
    /**
     * @emits destroyed
     */
    emitDestroyedEvent() {
      this.$emit("destroyed");
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.focusInputFieldOnMount();
  },
  /**
  |--------------------------------------------------
  | Destroyed lifecycle hook
  |--------------------------------------------------
  @description Cleaning up component stuff
  */
  destroyed() {
    this.emitDestroyedEvent();
    this.successMessage = "";
    this.codeErrorMessage = "";
    this.resendCodeAttempts = 0;

    if (this.codeTimerRef) {
      clearTimeout(this.codeTimerRef);
      this.codeTimerRef = null;
    }
  },
};
</script>

<style lang="scss">
.otp-input-field {
  input {
    text-align: center;
  }
}
</style>
